import { Routes, Route } from "react-router-dom";
import LayoutA from "./components/LayoutA";
import StatisticsLinks from "./features/public/StatisticsLinks";
import NotFound from "./features/public/NotFound";
import Login from "./features/auth/Login";

function App() {
  return (
    <Routes>
      <Route path="/">
        {/* public routes */}
        <Route path="/" element={<LayoutA />}>
          <Route path="login" element={<Login />} />
          <Route path="links/*" element={<StatisticsLinks />} />
        </Route>
      </Route>
      <Route element={<LayoutA />}>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
