import React from "react";
import { CheckCircle, Circle } from "@phosphor-icons/react";
import style from "./Checkbox.module.scss";

export const Checkbox = ({ checked = false, disabled = false, label = null, onChecked }) => {
  return (
    <div className={`${style.checkboxContainer} ${disabled ? "disabled" : ""} `} onClick={onChecked}>
      {checked && <CheckCircle size={20} />}
      {!checked && <Circle size={20} color="" />}
      {label && <label>{`${label}`}</label>}
    </div>
  );
};

export const Radio = ({ checked = false, disabled = true, onChecked, label = undefined }) =>
  Checkbox({
    checked,
    disabled,
    label,
    onChecked,
  });
