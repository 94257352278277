import { Link, Outlet } from "react-router-dom";
import srmLogoBlack from "../assets/img/SRM_CMYK.png";
const PublicHeader = () => {
  return (
    <header className="public-header">
      <div className="public-header-left-column">
        <div className="public-header-brand-name">
          <span className="h1 brand-text">SRM</span>
          <span className="h2 brand-text accent">Service</span>
        </div>
      </div>
      <div className="public-header-main-column" />
      <div className="public-header-right-column">
        <img src={srmLogoBlack} height={35} alt="SRM Logo Black" />
      </div>
    </header>
  );
};

const LayoutA = () => {
  return (
    <div className="root-container">
      <PublicHeader />
      <div className="grid-container">
        <div className="left-column">{/* Left Column */}</div>
        <div className="main-column">
          <Outlet />
        </div>
        <div className="right-column"></div>
      </div>
    </div>
  );
};
export default LayoutA;
