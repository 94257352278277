import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import usePersist from "../../hooks/usePersist";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";
import { EyeClosed } from "@phosphor-icons/react";
// import Popup from "../../components/Popup";
import { Checkbox, Input } from "../../components/basic";
import { Eye } from "@phosphor-icons/react/dist/ssr";

const Login = () => {
  useTitle("Login");
  //? Solo per il focus
  const userRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //?
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  //?
  const [persist, setPersist] = usePersist();
  //?
  const [login, { isLoading }] = useLoginMutation();

  //? focus user name field allo start
  useEffect(() => {
    userRef?.current?.focus();
  }, []);

  //? per rimuovere errore appena utente scrive
  useEffect(() => {
    setError();
  }, [email, password]);

  const handleOnLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email?.length) {
        setError("Username field empty");
        return;
      }
      if (!password?.length) {
        setError("Password field empty");
        return;
      }
      //? prendo il token e lo setto nel modello
      console.log("prima login", email, password, navigator.userAgent);
      const data = await login({
        email,
        password,
      }).unwrap();
      console.log("dopo login", data);
      dispatch(setCredentials({ csrf: data?.csrf, user: data?.user }));
      //? pulisco interfaccia
      setEmail("");
      setPassword("");
      // navigate("/dashboard");
    } catch (err) {
      console.log("errore login", err);
      if (!err.status) {
        // setError("No Server Response");
      } else if (err.status === 400) {
        // setError("Invalid Username or Password");
      } else if (err.status === 401) {
        // setError("Unauthorized");
      } else {
        // setError(err.data?.message);
      }
    }
  };

  const handleOnUserInputChange = (e) => setEmail(e.target.value);
  const handleOnPwdInputChange = (e) => setPassword(e.target.value);
  const handleOnTrustDeviceInputChange = () => setPersist(!persist);

  return (
    <section className={`login-main-container`}>
      <div className={`login-container`}>
        <div className="h1">Bentornato.</div>
        <form className={`login-form`} onSubmit={(event) => handleOnLogin(event)}>
          <Input label="Il tuo indirizzo email" onChange={handleOnUserInputChange} />
          <Input
            label="La tua password"
            type={passwordVisible ? "text" : "password"}
            suffix={passwordVisible ? <Eye /> : <EyeClosed />}
            onSuffixClicked={() => setPasswordVisible(!passwordVisible)}
            onChange={handleOnPwdInputChange}
          />
          <button style={{ marginTop: 10 }} type="submit">
            Accedi
          </button>
          <div>
            <Checkbox checked={persist} disabled={isLoading} onChange={handleOnTrustDeviceInputChange} label={"Ricorda questo dispositivo"} />
          </div>
          <div className={`login-suggestions`}>
            <Link className="h5">Problemi ad accedere?</Link>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
