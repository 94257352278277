import { apiSlice } from "../../app/api/apiSlice";

export const publicApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLink: builder.mutation({
      query: ({ resource }) => ({
        url: `links/${resource}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLinkMutation } = publicApiSlice;
