import React from "react";
import style from "./Input.module.scss";

const Input = ({
  value = undefined,
  onChange = undefined,
  onPrefixClicked = undefined,
  onSuffixClicked = undefined,
  prefix = undefined,
  suffix = undefined,
  label = undefined,
  placeholder = undefined,
  type = "text",
}) => {
  return (
    <div className={style.inputMain}>
      <label className={style.inputLabel}>{label}</label>
      <div className={style.inputContainer}>
        {prefix && (
          <div onClick={onPrefixClicked} className={`${style.icon} ${style.prefix}`}>
            {prefix}
          </div>
        )}
        <input
          value={value}
          onChange={onChange}
          className={`${prefix ? style.addPrefixPadding : null} ${suffix ? style.addSuffixPadding : null}`}
          type={type}
          placeholder={placeholder ?? ""}
        />
        {suffix && (
          <div onClick={onSuffixClicked} className={`${style.icon} ${style.suffix}`}>
            {suffix}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
