import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = async (args, api, extraOptions) => {
  console.log(args); // request url, method, body
  // console.log(api); // signal, dispatch, getState()
  // console.log(extraOptions); //custom like {shout: true}

  return fetchBaseQuery({
    baseUrl: args.url,
    timeout: 7000,
  })(args, api, extraOptions);
};

// const baseQueryWithReauth = async (args, api, extraOptions) => {
// console.log(args) // request url, method, body
// console.log(api) // signal, dispatch, getState()
// console.log(extraOptions) //custom like {shout: true}
// let result = await baseQuery(args, api, extraOptions);
// return result;
// };

export const publicApiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({}),
});
