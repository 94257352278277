import React, { useEffect } from "react";
import { waitFor } from "../../utils";
import { useGetLinkMutation } from "./publicApiSlice";

const InfoLinks = () => {
  const [getLink, { isLoading: isGetLinkLoading, data: linkData, isSuccess: isGetLinkSuccess, isError: isGetLinkError, error }] = useGetLinkMutation();

  useEffect(() => {
    async function startup() {
      const currentPath = window.location.pathname;
      console.log("Last path segment:", currentPath);
      const pathSegments = currentPath?.split("/");
      const lastPathSegment = pathSegments[pathSegments.length - 1];
      try {
        await waitFor(1000);
        await getLink({ resource: lastPathSegment });
      } catch (error) {
        console.log(error);
        // window.location.replace("https://www.srm.de");
      }
    }
    startup();
  }, [getLink]);

  useEffect(() => {
    if (isGetLinkError) {
      // window.location.replace("https://www.srm.de");
      console.log("errrrr", error);
      return;
    }
    if (isGetLinkLoading) {
      console.log("loading...");
      return;
    }
    if (isGetLinkSuccess) {
      console.log(linkData);
      window.location.replace(linkData.link);
      return;
    }
  }, [linkData, isGetLinkLoading, isGetLinkError, isGetLinkSuccess, error]);

  return (
    <div style={{ backgroundColor: "transparent", display: "flex", flexDirection: "column", alignItems: "center", rowGap: 10 }}>
      <div className="h3">We are redirecting you to the desired page.</div>
      <div className="h4">Please wait</div>
      <span className="loader"></span>
    </div>
  );
};

export default InfoLinks;
