import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials } from "../../features/auth/authSlice";

const PRODUCT_ID = "9005";
const USER_AGENT_NAME = "Service-Client";
const CLIENT_ID = "H2LTQM4PGC1EG25M";

const customHeaders = (headers) => {
  headers.set("X-CLIENT-API", "client=" + CLIENT_ID);
  headers.set("X-USER-AGENT", `${PRODUCT_ID}/${navigator.userAgent}`);
  return headers;
};

const baseQuery = async (args, api, extraOptions) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  console.log("console", apiKey, args);
  return fetchBaseQuery({
    // baseUrl: "http://192.168.10.61:3000",
    // baseUrl: "https://192.168.10.15:3030",
    baseUrl: "https://api.srmservice.com/v1",
    credentials: "include",
    timeout: 7000,

    prepareHeaders: (headers, { getState }) => {
      if (extraOptions?.auth) {
        const csrf = getState().auth?.csrf;
        if (csrf) {
          headers.set("X-CSRF-TOKEN", `${csrf}`);
        }
      }
      // console.log("ammodo", args?.body);
      return customHeaders(headers);
    },
  })(args, api, extraOptions);
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  console.log(args); // request url, method, body
  // console.log(api) // signal, dispatch, getState()
  // console.log(extraOptions) //custom like {shout: true}
  let result = await baseQuery(args, api, extraOptions);
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User"],
  endpoints: (builder) => ({}),
});
