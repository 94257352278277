import React from "react";
import useTitle from "../../hooks/useTitle";
import HtmlError from "../../components/basic/HtmlError";

const NotFound = () => {
  useTitle("Not found");
  return (
    <HtmlError
      status={404}
      title={
        <>
          Off the Beaten Path!
          <div style={{ fontSize: 30 }}>🚴‍♂️🌲</div>
        </>
      }
      message={"You've taken a detour into the unknown, but don't fret. We'll pump up your tires and get you the right track in no time!"}
    />
  );
};

export default NotFound;
