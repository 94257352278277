import React from "react";
import style from "./HtmlError.module.scss";
import logo2 from "../../assets/img/SRM_CMYK.png";
import { Link } from "react-router-dom";

const HtmlError = ({ status, title, message }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="error-code number-text" style={{}}>
        {status}
      </div>
      <div style={{}}>{title}</div>
      <div style={{}}>{message}</div>
    </div>
  );
};

export default HtmlError;
